import React from 'react'
import Img from 'gatsby-image'

export default ({ data }) => (
  <section className="hero site-wide grid-container grid-container--half grid-container--no-indent-right">
    <div className="grid-column">
      <h1 className="h2-styling">{data.title}</h1>
    </div>

    <div className="grid-column">
      <Img className="hero__image" fluid={data.heroImage.fluid} />
    </div>

    <div className="hero__scroll">
      <p>Scroll down</p>
      <svg className="hero__scroll-icon" x="0px" y="0px" viewBox="0 0 18 39.6">
        <path d="M9.7,39.1c0.5-0.1,1-0.2,1.4-0.3l0,0c3.7-1.5,6.3-6.9, 6.4-13.2c0-6.5-2.2-23.6-2.3-24.3c0-0.4-0.4-0.6-0.7-0.6 L3.8,0.5c-0.4,0-0.7,0.3-0.7,0.6C3,1.9,0.5,18.9,0.5,25.4c0,6.4,2.8,12.9,7.7,13.6C8.2,39,8.9,39.1,9.7,39.1z" />
        <line
          className="hero__scroll-line"
          x1="2.2"
          y1="7.1"
          x2="15.4"
          y2="7.1"
        />
      </svg>
    </div>
  </section>
)
