import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'

function RootIndex(props) {
  const siteTitle = get(props, 'data.site.siteMetadata.title')
  const posts = get(props, 'data.allContentfulBrunch.edges')
  const [hero] = get(props, 'data.allContentfulHero.edges')

  return (
    <Layout location={props.location}>
      <Helmet title={siteTitle} />
      <Hero data={hero.node} />
      <section className="brunch-list bg-grey-light">
        <div className="site-wide grid-container grid-container--half">
          {posts.map(({ node }) => {
            return (
              <div className="grid-column" key={node.slug}>
                <ArticlePreview article={node} />
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBrunch(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          location
          currency
          price
          stars
          duration
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    allContentfulHero(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          title
          heroImage: image {
            fluid(quality: 100, resizingBehavior: PAD) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
