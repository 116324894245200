import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import ScrollAnimation from 'react-animate-on-scroll'

export default ({ article }) => (
  <ScrollAnimation
    animateOnce
    animateIn="fade-in"
    offset={250}
    className="brunch-list__animation-layer"
  >
    <Link to={`/${article.slug}`} className="brunch-list__item">
      <Img
        alt=""
        fluid={article.heroImage.fluid}
        className="brunch-list__item-img"
      />

      <div className="brunch-list__item-info">
        <h2 className="h3-styling">{article.title}</h2>

        <h3 className="h6-styling">{article.location}</h3>

        <div className="brunch-list__item-stats">
          <div className="stat">
            <div className="stat__value">
              <h5>{article.duration}</h5>
            </div>
            <div className="stat__type meta-tag">mins</div>
          </div>
          <div className="stat">
            <div className="stat__value">
              <h5>{article.price}</h5>
            </div>
            <div className="stat__type meta-tag">{article.currency}</div>
          </div>
          <div className="stat">
            <div className="stat__value">
              <h5>{article.stars}</h5>
            </div>
            <div className="stat__type meta-tag">stars</div>
          </div>
        </div>
      </div>
    </Link>
  </ScrollAnimation>
)
